// import "./slider.css";
// import "../../assets/css/grid.css";
// import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
// import { useState, useEffect } from "react";
// import styled from "styled-components";
// import { mobile } from "../../responsive";
// import { sliderItems } from "../../data";
// const Container = styled.div`
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   position: relative;
//   overflow: hidden;
//   ${mobile({ display: "none" })}
// `;

// const Arrow = styled.div`
//   width: 50px;
//   height: 50px;
//   background-color: #999999;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: absolute;
//   top: 0;
//   bottom: 250px;
//   left: ${(props) => props.direction === "left" && "10px"};
//   right: ${(props) => props.direction === "right" && "10px"};
//   margin: auto;
//   cursor: pointer;
//   opacity: 0.2;
//   z-index: 2;
//   &:hover {
//     background-color: #1d4499;
//     color: white;
//     opacity: 0.8;
//   }
// `;
// const Wrapper = styled.div`
//   height: 100%;
//   display: flex;
//   transition: all 1.5s ease;
//   transform: translateX(${(props) => props.slideIndex * -100}vw);
// `;

// const Slide = styled.div`
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   background-color: #${(props) => props.bg};
// `;

// const ImgContainer = styled.div`
//   height: 100%;
//   flex: 1;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   top: 0;
// `;

// const Image = styled.img`
//   height: 80%;
// `;

// const InfoContainer = styled.div`
//   margin-top: -180px;
//   position: relative;
//   z-index: 1000;
// `;
// const Header = styled.p`
//   font-size: 70px;
//   font-weight: 1000;
//   font-size: 18px;
//   line-height: 21px;
//   letter-spacing: 0.16em;
//   text-transform: uppercase;
//   color: #159eec;
//   padding-bottom: 20px;
//   width: 550px;
// `;
// const Title = styled.p`
//   font-weight: 1000;
//   font-size: 40px;
//   line-height: 47px;
//   width: 613px;
//   color: #1d4499;
//   padding-bottom: 30px;
// `;

// const Desc = styled.p`
//   padding-bottom: 30px;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   width: 550px;
//   color: rgb(0, 0, 0);
// `;

// const Button = styled.button`
//   background: #bfd2f8;
//   border-radius: 50px;
//   border: none;
//   transition: 0.5s;
//   padding: 13px 35px;
//   color: #1f2b6c;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   cursor: pointer;
//   &:hover {
//     background-color: #1d4499;
//     color: white;
//   }
// `;

// const Slider = () => {
//   const [slideIndex, setSlideIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setSlideIndex((slideIndex) =>
//         slideIndex < sliderItems.length - 1 ? slideIndex + 1 : 0
//       );
//     }, 5000);

//     return () => {
//       clearInterval(interval);
//     };
//   }, []);

//   const handleClick = (direction) => {
//     if (direction === "left") {
//       setSlideIndex((slideIndex) => (slideIndex > 0 ? slideIndex - 1 : 2));
//     } else {
//       setSlideIndex((slideIndex) =>
//         slideIndex < sliderItems.length - 1 ? slideIndex + 1 : 0
//       );
//     }
//   };

//   return (
//     <Container>
//       <Arrow direction="left" onClick={() => handleClick("left")}>
//         <ArrowLeftOutlined style={{ fontSize: 40 }} />
//       </Arrow>
//       <Wrapper slideIndex={slideIndex}>
//         {sliderItems.map((item) => (
//           <Slide bg={item.bg} key={item.id}>
//             <ImgContainer></ImgContainer>
//             <div class="grid wide">
//               <InfoContainer>
//                 <Header>{item.header}</Header>
//                 <Title>{item.title}</Title>
//                 <Desc>{item.desc}</Desc>
//                 <Button>SẢN PHẨM</Button>
//               </InfoContainer>
//             </div>
//             <ImgContainer>
//               <Image src={item.img} />
//             </ImgContainer>
//           </Slide>
//         ))}
//       </Wrapper>
//       <Arrow direction="right" onClick={() => handleClick("right")}>
//         <ArrowRightOutlined style={{ fontSize: 40 }} />
//       </Arrow>
//     </Container>
//   );
// };

// export default Slider;

import React from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <div className="home">
      <div className="home__background"></div>
      <div className="grid wide">
        <div className="row">
          <div className="home__container__slider">
            <div className="home__container__slider--content">
              <h1>
                Khám Phá
                <br />
                Đất Nước
              </h1>
              <h1></h1>
            </div>
            <Link to="/tours">
              <div className="home__container__slider--btn">
                <div className="btn__bgc"></div>
                <a>
                  Đặt Nhanh
                  <span></span>
                  <span></span>
                  <span></span>
                </a>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
